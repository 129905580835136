import React, { useEffect, useRef } from "react"
import styled from 'styled-components'
import email from '../constants/email';

const Content = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  @media (min-width: 768px){
    width: 720px;
    padding: 160px 30px 120px;
  }
  p {
    font-size: 17px;
    line-height: 30px;
    opacity: 0.8;
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

const emailMapping = (string) => string.split('\n').filter(line => line !== '').map(line => {
  return `<p>${line}</p>`;
}).join('');

const Email = () => {
  const content = useRef();

  useEffect(() => {
    content.current.innerHTML = emailMapping(email)
  }, []);

  return <Content>
    <h2 style={{marginBottom: 40}}>이메일 무단 수집 거부</h2>
    <div ref={content}></div>
  </Content>
}

export default Email